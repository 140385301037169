* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  /* overflow-y: hidden; */
}

/* For Chrome, Safari, and Edge */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield !important;
}

input {
  text-transform: none !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  /* Adjust the width as needed */
  /* background-color: black; */
}

/* Set the styles for the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Color of the thumb */
  border-radius: 5px;
  /* Rounded corners for the thumb */
}

/* Set the styles for the scrollbar track on hover */
::-webkit-scrollbar-track:hover {}

/* Set the styles for the scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {}

@media (max-width: 769px) {
  ::-webkit-scrollbar {
    width: 0;
    /* Horizontal scrollbar */
    height: 0;
    /* Vertical scrollbar */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
  }
}

#pic,
#imAge,
#imAge-sign,
#video,
#music,
#imAgeTumb,
#picos,
#banner-logo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.light-table .ant-table-content {
  scrollbar-width: none !important;
}

.dark-table .ant-table-content {
  scrollbar-width: none !important;
}

.dark-table .ant-table-thead tr th {
  background-color: #1f242f !important;
  color: #cecfd2 !important;
  font-weight: 400 !important;
  border: none !important;
}

.light-table .ant-table-thead tr th {
  background-color: #edeef1 !important;
  color: #464c5e !important;
  font-weight: 400 !important;
  /* border: none !important; */
}

.light-table .ant-table-tbody tr td {
  background-color: #ffffff !important;
  color: #464c5e !important;
  /* border-bottom: 0.3px solid #1F242F !important; */
}

.dark-table .ant-table-tbody tr td {
  background-color: #0c111d !important;
  color: #94969c !important;
  border-bottom: 0.3px solid #1f242f !important;
}

.light-table .ant-pagination .ant-pagination-item-active {
  font-weight: 500 !important;
  /* background-color: #F05D24 !important; */
  border: none !important;
  transform: scale(1);
}

.light-table .ant-pagination .ant-pagination-item {
  font-weight: 500 !important;
  /* background-color: #151515 !important; */
  /* border-color: #151515; */
  /* border-radius: 15px; */
  margin: 0 0.5rem;
}

.dark-table .ant-pagination .ant-pagination-item {
  font-weight: 500 !important;
  background-color: #fff !important;
  /* border-color: #151515; */
  margin: 0 0.5rem !important;
}

.light-table .ant-pagination .ant-pagination-item-active a {
  background-color: #eef8ff !important;
  border-radius: 8px !important;
  border: none !important;
}

.light-table .ant-pagination .ant-pagination-item a {
  color: #24262d !important;
}

.dark-table .ant-pagination .ant-pagination-item {
  font-weight: 500 !important;
  background-color: transparent !important;
  border-color: none !important;
  margin: 0 0.5rem;
}

.dark-table .ant-pagination .ant-pagination-item-active a {
  color: #cbcbcb !important;
  background-color: #1f242f !important;
  border-radius: 8px !important;
}

.dark-table .ant-pagination .ant-pagination-item a {
  color: #cbcbcb !important;
}

.date-pico .react-datepicker__input-container {
  display: flex !important;
  align-items: center !important;
}

.light-theme .date-pico .react-datepicker__input-container .react-datepicker__calendar-icon {
  right: 0 !important;
}

.errortheme .date-pico .react-datepicker__input-container .react-datepicker__calendar-icon {
  right: 0 !important;
  top: 16px !important;
}

.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
  padding: 3px 24px !important;
}

.css-ypiqx9-MuiDialogContent-root {
  padding: 0 24px !important;
}

.time-picker-dark .react-time-picker__inputGroup input {
  color: #ffffff !important;
  /* Dark theme text color */
}

.time-picker-light .react-time-picker__inputGroup input {
  color: #161b26 !important;
  /* Light theme text color */
}

.time-picker-dark .react-time-picker__inputGroup__amPm {
  color: #ffffff !important;
  /* Change to your preferred color for dark theme */
}

/* For light theme */
.time-picker-light .react-time-picker__inputGroup__amPm {
  color: #161b26 !important;
  /* Change to your preferred color for light theme */
}

.th.ant-table-cell.ant-table-selection-column {
  border-start-start-radius: 0;
}

.border-border:where(.css-dev-only-do-not-override-p7e5j5).ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child {
  border-start-start-radius: 0 !important;
}

.border-border:where(.css-dev-only-do-not-override-p7e5j5).ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:last-child {
  border-start-end-radius: 20px !important;
}

:where(.css-dev-only-do-not-override-p7e5j5).ant-table-wrapper .ant-table-pagination-right {
  justify-content: center !important;
  background-color: transparent !important;
}

:where(.css-p7e5j5).ant-table-wrapper .ant-table-pagination-right {
  justify-content: center !important;
  background-color: transparent !important;
}

:where(.css-dev-only-do-not-override-p7e5j5).ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 0 !important;
  padding: 16px 20px !important;
  /* margin-bottom: 2rem !important */
}

:where(.css-p7e5j5).ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 0 !important;
  padding: 16px 20px !important;
}

:where(.css-dev-only-do-not-override-p7e5j5).ant-pagination::after {
  display: none !important;
}

:where(.css-p7e5j5).ant-pagination::after {
  display: none !important;
}

:where(.css-dev-only-do-not-override-p7e5j5).ant-pagination .ant-pagination-options {
  display: none !important;
}

:where(.css-p7e5j5).ant-pagination .ant-pagination-options {
  display: none !important;
}

.dark-table .ant-table-wrapper .ant-table-pagination-right {
  background-color: #0c111d !important;
}

:where(.css-dev-only-do-not-override-p7e5j5).ant-pagination .ant-pagination-item-active {
  border: none !important;
}

:where(.css-p7e5j5).ant-pagination .ant-pagination-item-active {
  border: none !important;
}

:where(.css-dev-only-do-not-override-p7e5j5).ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
:where(.css-dev-only-do-not-override-p7e5j5).ant-table-wrapper .ant-table-thead>tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none !important;
}

:where(.css-p7e5j5).ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
:where(.css-dev-only-do-not-override-p7e5j5).ant-table-wrapper .ant-table-thead>tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidth600px.css-1hju3x6-MuiPaper-root-MuiDialog-paper {
  border-radius: 12px !important;
}

:where(.css-dev-only-do-not-override-p7e5j5).ant-table-wrapper .ant-table {
  background-color: transparent !important;
}

:where(.css-p7e5j5).ant-table-wrapper .ant-table {
  background-color: transparent !important;
}

:where(.css-dev-only-do-not-override-p7e5j5).ant-table-wrapper .ant-table-thead>tr>th,
:where(.css-dev-only-do-not-override-p7e5j5).ant-table-wrapper .ant-table-thead>tr>td {
  border-bottom: 1px solid transparent !important;
}

:where(.css-p7e5j5).ant-table-wrapper .ant-table-thead>tr>th,
:where(.css-p7e5j5).ant-table-wrapper .ant-table-thead>tr>td {
  border-bottom: 1px solid transparent !important;
}

:where(.css-p7e5j5).ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:last-child {
  /* display: none !important; */
  border-radius: 0 8px 0 0 !important;
}

:where(.css-dev-only-do-not-override-p7e5j5).ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:last-child {
  border-radius: 0 8px 0 0 !important;
  /* display: none !important; */
}

:where(.css-p7e5j5).ant-table-wrapper .ant-table-thead>tr>th,
:where(.css-dev-only-do-not-override-p7e5j5).ant-table-wrapper .ant-table-thead>tr>td {
  /* border-radius: 0 8px 0 0 !important; */
  background-color: #edeef1 !important;
}

:where(.css-dev-only-do-not-override-p7e5j5).ant-table-wrapper .ant-table-thead>tr>th,
:where(.css-dev-only-do-not-override-p7e5j5).ant-table-wrapper .ant-table-thead>tr>td {
  /* border-radius: 0 8px 0 0 !important; */
  background-color: #edeef1 !important;
  /* padding: 14px 15px !important; */
  /* margin-left: 1rem; */
}

:where(.css-p7e5j5).ant-table-wrapper .ant-table-thead>tr>td,
:where(.css-p7e5j5).ant-table-wrapper .ant-table-thead>tr>th {
  /* border-radius: 0 8px 0 0 !important; */
  background-color: #edeef1 !important;
}

:where(.css-p7e5j5).ant-table-wrapper .ant-table-thead>tr>td,
:where(.css-p7e5j5).ant-table-wrapper .ant-table-thead>tr>th {
  /* border-radius: 0 8px 0 0 !important; */
  background-color: #edeef1 !important;
}

/* Targeting only the last header cell in each row for rounded corners */
:where(.css-p7e5j5, .css-dev-only-do-not-override-p7e5j5).ant-table-wrapper .ant-table-thead>tr>th:last-child,
:where(.css-p7e5j5, .css-dev-only-do-not-override-p7e5j5).ant-table-wrapper .ant-table-thead>tr>td:last-child {
  /* border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important; */
  border-radius: 8px !important;
  background-color: #edeef1 !important;
  /* Ensure your desired background color is maintained */
}

:where(.ant-table-thead) :where(tr) :where(th:last-child),
:where(.ant-table-thead) :where(tr) :where(td:last-child) {
  border-radius: 8px !important;
  background-color: #edeef1 !important;
}

.ant-table-thead>tr>th:last-child,
.ant-table-thead>tr>td:last-child {
  border-radius: 8px !important;
  /* Rounded corners on the right side */
  background-color: #edeef1 !important;
  /* Specific background color */
}

/* .react-loading-skeleton {
  width: 100% !important;
} */
.audio-player-custom audio {
  background-color: lightblue;
  /* Change this to your desired color */
}

:where(.css-dev-only-do-not-override-p7e5j5).ant-table-wrapper {
  box-shadow: 0px 1px 2px 0px #0000000d !important;
}

.dark-table .ant-table-thead tr th {
  background-color: #1f242f !important;
  color: #cecfd2 !important;
  font-weight: 400 !important;
  border: none !important;
}

.dark-table .ant-table-thead>tr>th:last-child,
.dark-table .ant-table-thead>tr>td:last-child {
  background-color: #1f242f !important;
  /* Dark theme background color */
  border-top-right-radius: 8px !important;
  /* Top right corner radius */
  border-bottom-right-radius: 8px !important;
  /* Bottom right corner radius */
  border: none !important;
  /* Remove any borders if present */
}

:where(.css-dev-only-do-not-override-p7e5j5).ant-table-wrapper .ant-table-thead>tr>th,
:where(.css-dev-only-do-not-override-p7e5j5).ant-table-wrapper .ant-table-thead>tr>td {}

/* Dark theme styles */
.dark-table .ant-table-thead>tr>th,
.dark-table .ant-table-thead>tr>td {
  background-color: #1f242f !important;
  /* Dark background */
}

/* Light theme styles */
.light-table .ant-table-thead>tr>th,
.light-table .ant-table-thead>tr>td {
  background-color: #edeef1 !important;
  /* Light background */
}

/* General scrollbar styling that applies to any theme */
.ant-table-content::-webkit-scrollbar {
  width: 6px;
  /* Width of the vertical scrollbar */
  height: 6px;
  /* Height of the horizontal scrollbar */
}

/* Light theme scrollbar styles */
.light-table .ant-table-content::-webkit-scrollbar-thumb {
  background-color: #c1c1c1 !important;
  /* Light grey for the thumb */
  border-radius: 5px;
}

.light-table .ant-table-content::-webkit-scrollbar-track {
  background-color: #f0f0f0 !important;
  /* Very light grey for the track */
}

/* Dark table scrollbar styles */
.dark-table .ant-table-content::-webkit-scrollbar-thumb {
  background-color: #555 !important;
  /* Darker grey for the thumb */
  border-radius: 5px;
}

.dark-table .ant-table-content::-webkit-scrollbar-track {
  background-color: #333 !important;
  /* Even darker grey for the track */
}

/* Hover styles for scrollbars in both themes */
.ant-table-content::-webkit-scrollbar-thumb:hover {
  background-color: #767676 !important;
}

.ant-table-selection-column {
  padding-left: 30px !important;
  width: 50px !important;
}

/* To make sure the checkbox is centered after changing padding */
.ant-table-selection-column .ant-checkbox-wrapper {
  display: flex;
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
}

.light-table .ant-pagination-item-ellipsis {
  color: #565e73 !important;
}

.dark-table .ant-pagination-item-ellipsis {
  color: #85888e !important;
}

.light-table .ant-table-body {
  background-color: #ffffff !important;
}

.dark-table .ant-table-body {
  background-color: #0c111d !important;
}

.light-table .ant-table-thead>tr>th.ant-table-column-has-sorters {
  background-color: #edeef1 !important;
  /* New background color */
}

.dark-table .ant-table-thead>tr>th.ant-table-column-has-sorters {
  background-color: #1f242f !important;
  /* New background color */
}

.css-1hju3x6-MuiPaper-root-MuiDialog-paper {
  background-color: transparent !important;
}

.css-1y6pnir {
  background-color: transparent !important;
}

.react-datepicker-wrapper {
  display: none !important;
}

.css-b62m3t-container {
  width: 100% !important;
}

/* Phone Input */
/* .apexcharts-bar-shadow .apexcharts-bar-series .apexcharts-bar {
  filter: drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.3));
} */
.custom-tooltip {
  padding: 10px 20px;
  /* Add padding to the tooltip */
  background-color: #333;
  /* Background color */
  color: white;
  /* Text color */
  text-align: center;
  /* Center the text */
  border-radius: 8px;
  /* Rounded corners */
  font-family: Arial, sans-serif;
  display: inline-block;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  /* Optional: Add shadow */
}

.MuiDialogContent-root.css-ypiqx9-MuiDialogContent-root {
  border-radius: 8px !important;
}

:where(.css-dev-only-do-not-override-p7e5j5).ant-table-wrapper .ant-table-cell-scrollbar:not([rowspan]) {
  box-shadow: none !important;
}

:where(.css-p7e5j5).ant-table-wrapper .ant-table-cell-scrollbar:not([rowspan]) {
  box-shadow: none !important;
}

:where(.css-p7e5j5).ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
:where(.css-p7e5j5).ant-table-wrapper .ant-table-thead>tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: transparent !important;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
  display: none !important;
}